const typefaces = `
@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-ExtraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/assets/resources/typefaces/Inter/Inter-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

/* === INTER DISPLAY ======================= */
@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-ExtraLightItalic.woff2') format('woff2');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-BoldItalic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-ExtraBoldItalic.woff2') format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Display';
    src: url('/assets/resources/typefaces/InterDisplay/InterDisplay-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}`;

export default typefaces;
