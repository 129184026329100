/* eslint-disable no-multi-spaces, quote-props */
const colors = {
  // Default
  foreground: '#FFFFFF',
  background: '#000000',
  base: {
    // Base                contrast ratio with background
    '100': '#EEEEEE',      // 18.09 : 1
    '95': '#E2E2E2',       // 16.21 : 1
    '90': '#D5D5D5',       // 14.03 : 1
    '85': '#C9C9C9',       // 12.68 : 1
    '80': '#BDBDBD',       // 11.17 : 1
    '75': '#B1B1B1',       // 9.79 : 1
    '70': '#A5A5A5',       // 8.52 : 1
    '65': '#9A9A9A',       // 7.46 : 1 (AAA minimum, paragraph)
    '60': '#8E8E8E',       // 6.40 : 1
    '55': '#838383',       // 5.53 : 1
    '50': '#787878',       // 4.75 : 1
    '45': '#6D6D6D',       // 4.05 : 1
    '40': '#636363',       // 3.49 : 1
    '35': '#585858',       // 2.95 : 1
    '30': '#4E4E4E',       // 2.52 : 1
    '25': '#444444',       // 2.15 : 1
    '20': '#3A3A3A',       // 1.84 : 1
    '15': '#303030',       // 1.59 : 1
    '10': '#272727',       // 1.40 : 1
    '05': '#1E1E1E',       // 1.25 : 1
    '00': '#151515',       // 1.14 : 1
  },
  primary: {
    // Primary             contrast ratio with background
    '100': '#9FFFFF',      // 18.22 : 1
    '95': '#90FFFF',       // 17.93 : 1
    '90': '#81F4FE',       // 16.30 : 1
    '85': '#72E5EF',       // 14.16 : 1
    '80': '#63D7E1',       // 12.33 : 1
    '75': '#53C9D3',       // 10.66 : 1
    '70': '#42BBC5',       // 9.14 : 1
    '65': '#2EADB7',       // 7.77 : 1 (AAA minimum, paragraph)
    '60': '#13A0AA',       // 6.63 : 1
    '55': '#00939C',       // 5.65 : 1
    '50': '#00858F',       // 4.75 : 1 (AA minimum)
    '45': '#007882',       // 4.00 : 1
    '40': '#006C76',       // 3.40 : 1
    '35': '#005F69',       // 2.84 : 1
    '30': '#00535D',       // 2.39 : 1
    '25': '#004751',       // 2.02 : 1
    '20': '#003B45',       // 1.71 : 1
    '15': '#00303A',       // 1.48 : 1 (border)
    '10': '#00262F',       // 1:31 : 1
    '05': '#001E24',       // 1.21 : 1
    '00': '#00151A',       // 1.12 : 1
  },
  secondary: {
    // Secondary           contrast ratio with background
    '100': '#BCFFFF',      // 18.88 : 1
    '95': '#ACF2FF',       // 16.89 : 1
    '90': '#9CE3FF',       // 14.84 : 1
    '85': '#8CD5FF',       // 13.07 : 1
    '80': '#7BC8FF',       // 11.54 : 1
    '75': '#6ABAFF',       // 10.08 : 1
    '70': '#58ADFF',       // 8.83 : 1
    '65': '#459FFF',       // 7.65 : 1 (AAA minimum, paragraph)
    '60': '#2D92FB',       // 6.61 : 1
    '55': '#0085EC',       // 5.56 : 1
    '50': '#0079DE',       // 4.78 : 1 (AA minimum)
    '45': '#006DCF',       // 4.08 : 1
    '40': '#0061C1',       // 3.47 : 1
    '35': '#0055B3',       // 2.95 : 1
    '30': '#004AA6',       // 2.53 : 1
    '25': '#003F98',       // 2.16 : 1
    '20': '#00348B',       // 1.86 : 1
    '15': '#002A7E',       // 1.63 : 1 (border)
    '10': '#002171',       // 1.45 : 1
    '05': '#001764',       // 1.30 : 1
    '00': '#000658',       // 1.16 : 1
  },
  tertiary: {
    // Tertiary            contrast ratio with background
    '100': '#FFE2FF',      // 17.57 : 1
    '95': '#FFD4FF',       // 16.11 : 1
    '90': '#FFC6FF',       // 14.77 : 1
    '85': '#FAB8FF',       // 13.36 : 1
    '80': '#EBAAFF',       // 11.72 : 1
    '75': '#DC9CFF',       // 10.24 : 1
    '70': '#CE8FFF',       // 8.99 : 1
    '65': '#BF81FD',       // 7.77 : 1 (AAA minimum, paragraph)
    '60': '#B174EF',       // 6.61 : 1
    '55': '#A367E0',       // 5.57 : 1
    '50': '#955AD2',       // 4.67 : 1 (AA minimum)
    '45': '#874EC4',       // 3.91 : 1
    '40': '#7941B6',       // 3.24 : 1
    '35': '#6B35A8',       // 2.68 : 1
    '30': '#5D289B',       // 2.24 : 1
    '25': '#501B8D',       // 1.88 : 1
    '20': '#420C80',       // 1.59 : 1
    '15': '#340073',       // 1.39 : 1 (border)
    '10': '#250066',       // 1.27 : 1
    '05': '#15005A',       // 1.17 : 1
    '00': '#0A004E',       // 1.12 : 1
  },
  highlight: {
    // Highlight           contrast ratio with background
    '100': '#FFD1B4',      // 15.03 : 1
    '95': '#FFC3A7',       // 13.61 : 1
    '90': '#FFB599',       // 12.32 : 1
    '85': '#FFA68C',       // 11.08 : 1
    '80': '#FF987F',       // 10.04 : 1
    '75': '#FF8A72',       // 9.13 : 1
    '70': '#FF7C66',       // 8.32 : 1
    '65': '#FF6F59',       // 7.67 : 1 (AAA minimum, paragraph)
    '60': '#F3614D',       // 6.62 : 1
    '55': '#E35342',       // 5.58 : 1
    '50': '#D34536',       // 4.67 : 1 (AA minimum)
    '45': '#C4362B',       // 3.90 : 1
    '40': '#B42720',       // 3.25 : 1
    '35': '#A41415',       // 2.68 : 1
    '30': '#950009',       // 2.28 : 1
    '25': '#860000',       // 2.01 : 1
    '20': '#770000',       // 1.78 : 1
    '15': '#690000',       // 1.60 : 1 (border)
    '10': '#5B0000',       // 1.44 : 1
    '05': '#4F0000',       // 1.33 : 1
    '00': '#460000',       // 1.26 : 1
  },
};

export default colors;
