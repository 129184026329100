import * as Sentry from '@sentry/react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const UserDataContext = createContext();

export default function UserDataProvider({ children }) {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setStatus('success');
    });
    return () => {
      unsubscribe();
      setUser(null);
      setStatus('loading');
    };
  }, [auth]);

  useEffect(() => {
    if (user?.uid) {
      Sentry.setUser({
        id: user?.uid,
      });
    }
    return () => {
      Sentry.setUser(null);
    };
  }, [user]);

  const value = useMemo(() => ({
    user,
    status,
  }), [user, status]);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
}
UserDataProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
