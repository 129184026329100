import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { PopupModal } from 'react-calendly';
import ReactDOM from 'react-dom';

import { useUserData } from '../../app/userDataProvider';

export default function Calendly({ url, isOpen, close }) {
  const { user } = useUserData();

  useEffect(() => {
    const overlay = document.querySelector('.calendly-overlay');
    if (overlay) {
      if (isOpen) {
        overlay.addEventListener('click', close);
        return () => {
          overlay.removeEventListener('click', close);
        };
      }
      overlay.removeEventListener('click', close);
    }
    return () => {};
  }, [isOpen]);

  const prefill = useMemo(() => {
    if (user?.email) {
      return { email: user.email };
    }
    return undefined;
  }, [user]);

  const utm = useMemo(() => {
    const returnObj = {};
    const urlParams = new URLSearchParams(window.location.search);
    let found = false;
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'].forEach((param) => {
      const value = urlParams.get(param);
      if (value) {
        found = true;
        returnObj[param] = value;
      }
    });
    if (found) {
      return returnObj;
    }
    return undefined;
  }, []);

  return (
    ReactDOM.createPortal(<PopupModal
      url={url}
      open={isOpen}
      onModalClose={close}
      pageSettings={{
        backgroundColor: '0d1117',
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
        primaryColor: 'ffffff',
        textColor: 'ffffff',
        hideGdprBanner: true,
      }}
      rootElement={document.body}
      utm={utm}
      prefill={prefill}
    />, document.body)
  );
}

Calendly.propTypes = {
  url: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

Calendly.defaultProps = {
  isOpen: false,
};
