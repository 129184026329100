import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React from 'react';

import theme from '../components/form/theme';
import themeV2 from '../mui-styles/themeV2';
import EmulatorProvider from './emulatorProvider';
import InitializeFirebaseApp from './initializeFirebaseApp';
import UserDataProvider from './userDataProvider';

export function ErrorFallback() {
  return (
    <div className="error w-dyn-empty margin-bottom">
      <div>
        Something went wrong.
      </div>
    </div>
  );
}

export default function AppProviders({ useV2, children }) {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
      <InitializeFirebaseApp>
        <EmulatorProvider>
          <UserDataProvider>
            <ThemeProvider theme={useV2 ? themeV2 : theme}>
              {children}
            </ThemeProvider>
          </UserDataProvider>
        </EmulatorProvider>
      </InitializeFirebaseApp>
    </Sentry.ErrorBoundary>
  );
}

AppProviders.propTypes = {
  useV2: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
AppProviders.defaultProps = {
  useV2: false,
};
