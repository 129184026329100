import { css } from '@emotion/react';
import { createTheme } from '@mui/material/styles';

import colors from './colors';
import components from './components';
import * as constants from './constants';

// === MUI THEME ==============================
const themeV2 = createTheme({
  breakpoints: {
    values: {
      ...createTheme().breakpoints.values, // keep default MUI breakpoints
      switchBarScale: constants.switchBarScale,
      switchGlobalHeaderToHamburger: constants.switchGlobalHeaderToHamburger,
      switchSidebarToHamburger: constants.switchSidebarToHamburger,
      noOuterMargin: constants.noOuterMargin,
    },
    unit: 'px',
  },
  shape: {
    borderRadius: 0,
  },
  colors,
  palette: {
    primary: {
      main: colors.base['90'],
      light: colors.base['100'],
      dark: colors.base['80'],
    },
    secondary: {
      main: colors.background,
      light: colors.background,
      dark: colors.background,
    },
    error: {
      main: colors.highlight['65'],
      light: colors.highlight['75'],
      dark: colors.highlight['50'],
    },
    text: {
      main: colors.base['65'],
      focused: colors.foreground,
      disabled: colors.base['50'],
    },
    background: {
      main: colors.background,
      focused: colors.background,
    },
    border: {
      main: colors.base['15'],
      focused: colors.foreground,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    text2to1: constants.text2to1,
    text3to2: constants.text3to2,
    text12to11: constants.text12to11,
    text12to11Heading: css`
      ${constants.text12to11}
      color: ${colors.foreground};
      font-weight: 700;
    `,
    paragraph: constants.paragraph,
  },
  components,
  sizes: constants.sizes,
  border: {
    width: constants.borderWidth,
    default: constants.borderDefault,
    prominentColor: constants.borderProminentColor,
    prominent: `solid ${constants.borderWidth}px ${constants.borderProminentColor}`,
  },
  outline: {
    width: constants.outlineWidth,
    color: constants.outlineColor,
    focus: constants.outline,
  },
  line: {
    radius: constants.lineRadius,
  },
  icon: {
    withStroke: constants.iconWithStroke,
    xs: constants.iconXs,
    sm: constants.iconSm,
    md: constants.iconMd,
    circle: {
      styling: constants.iconCircle,
      size: constants.iconCircleSize,
      margin: constants.iconCircleMargin,
    },
  },
  transition: {
    fast: constants.transitionFast,
    timing: {
      fast: constants.transitionTimingFast,
      normal: constants.transitionTimingNormal,
    },
  },
  stripe: constants.stripe,
  visuallyHidden: css`
    position: absolute;
    white-space: nowrap;
    clip: rect(0 0 0 0);

    width: 1px;
    height: 1px;

    padding: 0;
    border: 0;
    margin: 0;

    overflow: hidden;
  `,
});

export default themeV2;
