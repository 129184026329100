import { getApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import PropTypes from 'prop-types';

export default function EmulatorProvider({ children }) {
  const app = getApp();
  const functions = getFunctions(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);

  if (process.env.REACT_APP_USE_FUNCTIONS_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.log('Connecting to Functions emulator');
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  }
  if (process.env.REACT_APP_USE_FIRESTORE_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.log('Connecting to Firestore emulator');
    connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  }
  if (process.env.REACT_APP_USE_STORAGE_EMULATOR === 'true') {
    // eslint-disable-next-line no-console
    console.log('Connecting to Storage emulator');
    connectStorageEmulator(storage, '127.0.0.1', 9199);
  }

  return children;
}

EmulatorProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
