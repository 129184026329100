import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Loading from '../Loading';

const ViewPartContext = createContext();
export default function ViewPartProvider({ children }) {
  const params = new URLSearchParams(window.location.search);
  const partID = params.get('quote');

  const [part, setPart] = useState({});
  const [partQueryStatus, setPartQueryStatus] = useState('loading');
  const [quote, setQuote] = useState({});
  const [quoteQueryStatus, setQuoteQueryStatus] = useState('loading');

  useEffect(() => {
    if (!partID) return undefined;
    const unsubscribePart = onSnapshot(
      doc(getFirestore(), 'parts', partID),
      (snapshot) => {
        setPart({ id: snapshot.id, ...snapshot.data() });
        setPartQueryStatus('success');
      },
      () => {
        setPartQueryStatus('error');
      },
    );

    return () => unsubscribePart();
  }, [partID]);

  useEffect(() => {
    if (!part?.quote) return undefined;
    const unsubscribeQuote = onSnapshot(
      doc(getFirestore(), 'quotes', part.quote),
      (snapshot) => {
        setQuote({ id: snapshot.id, ...snapshot.data() });
        setQuoteQueryStatus('success');
      },
      () => {
        setQuoteQueryStatus('error');
      },
    );

    return () => unsubscribeQuote();
  }, [part?.quote]);

  const value = useMemo(() => ({
    partID,
    part,
    quote,
    expired: Date.now() - part.expiresAt > 0,
  }), [part, partID, quote]);

  if (partQueryStatus === 'loading' || quoteQueryStatus === 'loading') {
    return <Loading />;
  }

  return (
    <ViewPartContext.Provider value={value}>
      {children}
    </ViewPartContext.Provider>
  );
}
ViewPartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useViewPart = () => {
  const context = useContext(ViewPartContext);
  if (context === undefined) {
    throw new Error('useViewPart must be used within a ViewPartProvider');
  }
  return context;
};
