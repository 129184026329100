import { css } from '@emotion/react';

import colors from './colors';

// === CONSTANTS ==============================
// borders
export const borderWidth = 1;
export const borderProminentColor = colors.base['30'];
export const borderDefault = `solid ${borderWidth}px ${colors.base['15']}`;

// outlines
export const outlineWidth = 1;
export const outlineColor = colors.foreground;
export const outline = `solid ${outlineWidth}px ${outlineColor}`;

// lines
export const lineRadius = 7;

// icons
export const iconCircleSize = 35;
export const iconCircleMargin = -2;
export const iconWithStroke = css`
  display: block;
  width: 100%;
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1px;
`;
export const iconXs = 18;
export const iconSm = 19;
export const iconMd = 35;
export const iconCircle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${iconCircleSize}px;
  height: ${iconCircleSize}px;

  border-radius: 200px;
  margin: ${iconCircleMargin}px;
`;

// transitions
export const transitionTimingFast = '0.1s';
export const transitionFast = `${transitionTimingFast} ease-out`;
export const transitionTimingNormal = '0.2s';

// breakpoints
export const switchBarScale = 600;
export const switchGlobalHeaderToHamburger = 1100;
export const switchSidebarToHamburger = 940;
export const noOuterMargin = 1150;

// sizes
export const sizes = {
  maxWidth: 1430,
  sideBarWidth: 300,
  menuButtonHeight: 50,
  outerMargin: 20,
  outerPaddingBlock: 23,
  outerPaddingInline: 23,
  inputPadding: 18,
};

// typography
export const text2to1 = css`
  max-width: 100%;
  color: ${colors.foreground};
  font-family: 'Inter Display', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 3.47rem;
  line-height: 1.04em;
  overflow-wrap: break-word;
  margin: 0;

  @media screen and (max-width: 1000px) {
    font-size: 3.25rem;
    line-height: 1em;
  }

  @media screen and (max-width: ${switchSidebarToHamburger}px) {
    font-size: 3.1rem;
  }

  @media screen and (max-width: ${noOuterMargin}px) {
    font-size: 2.9rem;
  }
`;
export const text3to2 = css`
  max-width: 100%;
  color: ${colors.foreground};
  font-family: 'Inter Display', sans-serif;
  font-weight: 700;
  font-size: 2.23rem;
  line-height: 1.21em;
  overflow-wrap: break-word;
  margin: 0;
`;
export const text12to11 = css`
  color: ${colors.base['65']};
  font-family: 'Inter', 'sans-serif';
  font-weight: 400;
  font-size: 1.22rem;
  text-transform: none;
  line-height: 1.61em;
  margin: 0;
`;
export const paragraph = css`
  color: ${colors.base['65']};
  font-family: 'Inter', 'sans-serif';
  font-weight: 400;
  font-size: 1rem;
  text-transform: none;
  line-height: 1.8em;
  margin: 0;
`;

// Stripe
export const stripe = {
  fontSizeBase: 16,
  spacingUnit: 8,
};
