import { getApps, initializeApp } from 'firebase/app';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import firebaseConfig from './firebaseConfig';

export default function InitializeFirebaseApp({ children }) {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    try {
      const existingApps = getApps();
      if (existingApps.length > 0) {
        setInitialized(true);
        return;
      }
      initializeApp(firebaseConfig);
      setInitialized(true);
    } catch (error) {
      setInitialized(false);
    }
  }, []);

  if (!initialized) return null;

  return children;
}

InitializeFirebaseApp.propTypes = {
  children: PropTypes.node.isRequired,
};
