import { css } from '@emotion/react';

import colors from './colors';
import * as constants from './constants';
import typefaces from './typefaces';

const components = {
  // === MUI COMPONENTS =========================
  MuiCssBaseline: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: typefaces,
  },

  MuiBackdrop: {
    styleOverrides: {
      root: css`
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
      `,
      invisible: css`
        background: transparent;
        backdrop-filter: none;
      `,
    },
  },

  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: css`
        ${constants.paragraph}
        min-width: unset;
        padding: 0;
      `,
    },
  },

  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: css`
        flex-shrink: 0;
        color: ${colors.base['65']};
        margin-top: -5px;
        transition: color ${constants.transitionFast};

        &:hover, &:focus {
          color: ${colors.base['90']};
        }

        &:focus-visible, &:active {
          color: ${colors.foreground};
        }

        .PrivateSwitchBase-input {
          &:focus-visible, &:active {
            & + .MuiSvgIcon-root {
              outline: ${constants.outline};
            }
          }
        }
      `,
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: css`
        gap: 15px;
        max-height: 100vh;
        padding:
          18px
          ${constants.sizes.outerPaddingInline}px
          22px
          ${constants.sizes.outerPaddingInline}px;
        border: ${constants.borderDefault};
        margin: 0 ${constants.sizes.outerPaddingInline}px;
        background-color: var(--clr-background);
        overflow-x: hidden; // prevent horizontal scroll

        @media screen and (max-width: 355px) {
          margin: 0 5px;
        }
      `,
      paperWidthMd: css`
        max-width: 750px;
      `,
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: css`
        align-items: flex-start;
      `,
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: css`
        ${constants.paragraph}
        line-height: 1.5em;
        margin: 0;
        margin-bottom: 8px;
        transition: color ${constants.transitionFast};
        pointer-events: all;

        &.Mui-focused {
          color: ${colors.base['65']};
        }
      `,
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: css`
        ${constants.paragraph}
        line-height: 1em;
        box-sizing: border-box;
      `,
      input: css`
        height: unset;
        color: ${colors.base['80']};
        padding: ${constants.sizes.inputPadding}px;
        border: ${constants.borderWidth}px solid ${colors.base['25']};
        border-radius: 0;
        transition:
          color ${constants.transitionFast},
          border-color ${constants.transitionFast};

        &:hover, &:focus {
          color: ${colors.base['90']};
          border-color: ${colors.base['35']};
        }

        &:focus-visible, &:active {
          color: ${colors.foreground};
          border-color: ${colors.foreground};
        }
      `,
    },
  },

  MuiInputLabel: {
    defaultProps: {
      shrink: false,
    },
    styleOverrides: {
      root: css`
        position: relative;
        transform: none;
      `,
    },
  },

  MuiLink: {
    styleOverrides: {
      root: css`
        color: ${colors.base['65']};

        &:hover, &:focus {
          color: ${colors.base['85']};
        }

        &:focus-visible, &:active {
          color: ${colors.foreground};
        }
      `,
    },
  },

  MuiMenu: {
    styleOverrides: {
      list: css`
          border: ${constants.borderDefault};
          background-color: ${colors.background};
        `,
      select: css`
          min-height: unset;
          padding-right: 46px;
        `,
    },
  },

  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: css`
        position: relative;
        color: ${colors.base['65']};
        transition:
          color ${constants.transitionFast},
          background-color ${constants.transitionFast};

        &:hover, &:focus {
          color: ${colors.base['90']};
        }

        &:active, &:focus-visible {
          color: ${colors.foreground};
          outline: ${constants.outline};
          z-index: 2;
        }

        &.Mui-selected {
          color: ${colors.foreground};
          background-color: ${colors.base['05']};

          &:hover, &:focus,
          &:active, &:focus-visible {
            background-color: ${colors.base['10']};
          }
        }
      `,
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      notchedOutline: css`
        display: none;
      `,
    },
  },

  MuiRadio: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: css`
        color: ${colors.base['65']};
        transition: color ${constants.transitionFast};

        &:hover, &:focus {
          color: ${colors.base['80']};
        }

        &:focus-visible, &:active {
          color: ${colors.foreground};
        }

        &.Mui-checked {
          color: ${colors.foreground};
        }

        .PrivateSwitchBase-input {
          &:focus-visible ,&:active {
            & + span {
              // focus outline
              outline: ${constants.outline};
              outline-offset: 1px;
              border-radius: 100px;
            }
          }
        }
      `,
    },
  },

  MuiSelect: {
    styleOverrides: {
      root: css`
        .accordion-arrow {
          position: absolute;
          right: 0;
          pointer-events: none;
        }
      `,
      select: css`
        min-height: unset;
        padding-right: 46px;
      `,
    },
  },

  MuiTable: {
    styleOverrides: {
      root: css`
        width: 100%;
      `,
    },
  },

  MuiTableCell: {
    styleOverrides: {
      root: css`
        ${constants.paragraph}
        border-top: ${constants.borderDefault};
        border-bottom: none;
      `,
    },
  },

  MuiTableRow: {
    styleOverrides: {
      root: css`
        vertical-align: top;
        & .MuiTableCell-root:first-of-type {
          padding-left: 0;
        }
        & .MuiTableCell-root:last-child {
          padding-right: 0;
        }
      `,
    },
  },

  MuiTextField: {
    styleOverrides: {
      root: css`
        width: 100%;
      `,
    },
  },

  MuiTypography: {
    defaultProps: {
      variantMapping: {
        text2to1: 'h1',
        text3to2: 'h2',
        text12to11: 'h5',
        paragraph: 'p',
      },
    },
  },

  // === CUSTOM COMPONENTS ======================
  InteractiveBox: {
    styleOverrides: {
      root: css`
        display: block;
        position: relative;
        z-index: 1;
        color: ${colors.base['70']};
        text-decoration: none;
        border: ${constants.borderDefault};
        background-color: ${colors.background};
        transition:
          color ${constants.transitionFast},
          background-color ${constants.transitionFast},
          border-color ${constants.transitionFast};

        &:hover, &:focus {
          color: ${colors.base['90']};
          border-color: ${colors.base['30']};
          background-color: ${colors.background};
          z-index: 2;
        }

        &:active, &:focus-visible {
          color: ${colors.foreground};
          border-color: ${constants.outlineColor};
          background-color: ${colors.background};
          outline: none;
          z-index: 2;
        }

        &:disabled {
          color: ${colors.base['50']};
          cursor: not-allowed;
        }
      `,
    },
    variants: [
      {
        props: { variant: 'pill' },
        style: css`
          border-radius: 200px;
          padding: 5px ${constants.sizes.outerPaddingInline}px;
          margin: 0 ${constants.iconCircleMargin}px;
        `,
      },
      {
        props: { variant: 'pill', size: 'sm' },
        style: css`
          font-size: 0.875rem;
          padding: 4px ${constants.sizes.outerPaddingInline - 2}px;
        `,
      },
      {
        props: { variant: 'pillWithIcon' },
        style: css`
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          text-align: left;
          line-height: 1.2em;
          padding: 2px 2px 2px ${constants.sizes.outerPaddingInline}px;
          border-radius: 200px;
          margin: 0 ${constants.iconCircleMargin}px;

          &:hover, &:focus {
            .iconCircle {
              background-color: ${colors.base['100']};
            }
          }

          &:active, &:focus-visible {
            .iconCircle {
              background-color: ${colors.foreground};
            }
          }

          .iconCircle {
            ${constants.iconCircle}
            flex-shrink: 0;
            color: ${colors.background};
            margin: 0;
            background-color: ${colors.base['90']};
            transition: background-color ${constants.transitionFast};
          }
        `,
      },
      {
        props: { variant: 'circle' },
        style: css`
          display: flex;
          align-items: center;
          justify-content: center;
          width: ${constants.iconCircleSize}px;
          height: ${constants.iconCircleSize}px;
          border-radius: 200px;
          margin: ${constants.iconCircleMargin}px;
        `,
      },
      {
        props: { styling: 'attention' },
        style: css`
          color: ${colors.base['90']};
          font-weight: 500;

          &:hover, &:focus, &:focus-visible, &:active {
            color: ${colors.foreground};
          }
        `,
      },
      {
        props: { styling: 'main' },
        style: css`
          color: ${colors.background};
          background-color: ${colors.base['90']};

          &:hover, &:focus {
            color: ${colors.background};
            background-color: ${colors.base['100']};
          }

          &:active, &:focus-visible {
            color: ${colors.background};
            background-color: ${colors.foreground};
            outline: ${constants.outline};
            outline-offset: 2px;
          }

          &:disabled {
            color: ${colors.base['10']};
            background-color: ${colors.base['70']};
            border-color: ${colors.base['80']};

            &:active, &:focus-visible {
              outline: none;
            }
          }
        `,
      },
    ],
  },
};

export default components;
