import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useUserData } from '../../app/userDataProvider';

function EnsureUnAuth({ children }) {
  const { status, user } = useUserData();

  useEffect(() => {
    if (status === 'loading') return;
    if (user && user?.email) {
      window.location.href = '/';
    }
  }, [status, user]);

  return children;
}

EnsureUnAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default EnsureUnAuth;
